export default class PostModel {
  constructor() {
    this.objRaw = {};
    this.id = '';
    this.status = 'HIDDEN';
    this.useComments = false;
    this.thumbnail = [];
    this.shareThumbnail = [];
    this.title = '';
    this.link = '';
    this.postType = 'NORMAL';
    this.contents = '';
    this.createdAt = '';
  }
  setData(obj) {
    if (obj) this.objRaw = obj;
    const {
      id,
      status,
      useComments,
      thumbnail,
      shareThumbnail,
      title,
      link,
      postType,
      contents,
      createdAt,
      updatedAt,
    } = obj;
    this.id = id;
    this.status = status;
    this.useComments = useComments;
    if (thumbnail) {
      this.thumbnail = [
        {
          src: thumbnail,
        },
      ];
    }
    if (shareThumbnail) {
      this.shareThumbnail = [
        {
          src: shareThumbnail,
        },
      ];
    }
    this.title = title;
    this.postType = postType;
    this.link = link;
    this.contents = contents;
    this.createdAt = createdAt;
    this.updatedAt = updatedAt;
  }
  setStatusHidden(){
    this.status = 'HIDDEN';
  }
  setPostTypeModifyData(obj, status) {
    // status : 'ADD','REMOVE'
    const { title, link, contents } = obj;
    let rObj = {
      title: title,
      postType: '',
      link: link,
      contents: contents,
    };
    switch (status) {
      case 'ADD': {
        rObj.postType = 'HOT';
        break;
      }
      case 'REMOVE': {
        rObj.postType = 'NORMAL';
        break;
      }
    }
    return rObj;
  }
  getRegisterData() {
    let obj = {
      status: this.status,
      useComments : this.useComments,
      thumbnail: this.thumbnail.length > 0 ? this.thumbnail[0].src : '',
      shareThumbnail:
        this.shareThumbnail.length > 0
          ? this.shareThumbnail[0].src
          : '',
      title: this.title,
      postType: 'NORMAL',
      link: this.link,
      contents: this.contents,
    };
    return obj;
  }
  getModifyData() {
    let obj = {
      status: this.status,
      useComments : this.useComments,
      thumbnail: this.thumbnail.length > 0 ? this.thumbnail[0].src : '',
      shareThumbnail:
        this.shareThumbnail.length > 0
          ? this.shareThumbnail[0].src
          : '',
      title: this.title,
      postType: this.postType,
      link: this.link,
      contents: this.contents,
    };
    return obj;
  }
}
