
import { convertDateFormat } from '@lemontree-ai/lemontree-admin-common-front/utils/dateUtils';
import { getSelectDataList } from "@lemontree-ai/lemontree-admin-common-front/utils/selectDataUtils";
import { wordToUpperCase } from "@lemontree-ai/lemontree-admin-common-front/utils/stringUtils";

export default class AdvertisementModel {
  constructor() {
    this.id = null;
    this.code = '';
    this.target = 'ALL';
    this.inventory = 'SECTION';
    this.title = '';
    this.description = '';
    this.link = '';
    this.image = '';
    this.imageList = [];
    this.priority = 1;
    this.isAdvertisement = true;
    this.backgroundColor = '#FFFFFF';
    this.backgroundColorInput = '#FFFFFF';
    this.startAt = convertDateFormat(new Date(), 'YYYY-MM-DDTHH:mm:ss');
    this.endAt = convertDateFormat(new Date(), 'YYYY-MM-DDTHH:mm:ss');
    this.status = 'REGISTERED';
    this.createdBy = '';
    this.createdAt = '';
  }
  setData(obj) {
    if (obj) this.objRaw = obj;
    const {
      id,
      code,
      target,
      inventory,
      title,
      description,
      link,
      image,
      priority,
      isAdvertisement,
      backgroundColor,
      startAt,
      endAt,
      status,
      createdBy,
      createdAt,
    } = obj;
    this.code = code;
    this.target = target;
    this.inventory = inventory;
    this.title = title;
    this.description = description;
    this.link = link;
    this.image = image;
    if(image){
      this.imageList = [
        {
          src : image,
        }
      ]
    }
    this.priority = priority;
    this.isAdvertisement = isAdvertisement;
    if(backgroundColor){
      const bgcDataList = getSelectDataList('advertisement_section_background_color');
      if(bgcDataList.find(item => item.id === backgroundColor) ){
        this.backgroundColor = backgroundColor;
      }else{
        this.backgroundColor = '';
        this.backgroundColorInput = backgroundColor;
      }
    }
    this.startAt = startAt;
    this.endAt = endAt;
    this.status = status;
    if(id){
      this.id = id;
      this.createdBy = createdBy;
      this.createdAt = createdAt;
    }
  }
  getData() {
    let obj = {
      code: this.code,
      target: this.target,
      inventory: this.inventory,
      title: this.title,
      description: this.description,
      link: this.link,
      image: this.imageList.length > 0 ? this.imageList[0].src : '',
      priority: this.priority,
      isAdvertisement: this.isAdvertisement,
      backgroundColor: wordToUpperCase(this.backgroundColor || this.backgroundColorInput),
      startAt: convertDateFormat(this.startAt,'YYYY-MM-DDTHH:mm:ss'),
      endAt: convertDateFormat(this.endAt,'YYYY-MM-DDTHH:mm:ss'),
      status: this.status,
    };
    return obj;
  }
}
