<template>
  <PageWithLayout>
    <TableView>
      <template v-slot:tbl_colgroup>
        <col style="width:190px"/>
        <col/>
        <col style="width:190px"/>
        <col/>
      </template>
      <!-- <template v-slot:tbl_top>
        <div class="area_left">
          <strong class="tit_tbl">포스트 상세</strong>
        </div>
      </template> -->
      <template v-slot:tbl_body>
        <tr>
          <th>상태</th>
          <td colspan="3">
            <span class="txt_tbl">
              <Badge
                :text="viewModel.model.status | convertIdToText('blog_status')"
                :badgeStyle="viewModel.model.status | convertIdToColor('blog_status')"
                :badgeFill="false" />
            </span>
          </td>
        </tr>
        <tr>
          <th>추천 포스트</th>
          <td>
            <span class="txt_tbl">
              <Badge
                v-if="viewModel.model.postType === 'HOT'"
                :text="viewModel.model.postType | convertIdToText('post_type')"
                :badgeStyle="viewModel.model.postType | convertIdToColor('post_type')"
                :badgeFill="false" />
              <template v-else>-</template>
            </span>
          </td>
          <th>댓글유무</th>
          <td>
            <span class="txt_tbl">
              {{ viewModel.model.useComments | convertIdToText('blog_comment') }}
            </span>
          </td>
        </tr>
        <tr>
          <th>등록일시</th>
          <td>
            <span class="txt_tbl">{{ viewModel.model.createdAt | convertDateFormat() }}</span>
          </td>
          <th>최종수정일시</th>
          <td>
            <span class="txt_tbl">{{ viewModel.model.updatedAt | convertDateFormat() }}</span>
          </td>
        </tr>
        <tr>
          <th>이미지</th>
          <td colspan="3">
            <img
              v-if="viewModel.model.thumbnail.length > 0"
              :src="viewModel.model.thumbnail[0].src"
              class="img_g">
            <span v-else class="txt_tbl">-</span>
          </td>
        </tr>
        <tr>
          <th>SNS 공유 이미지</th>
          <td colspan="3">
            <img
              v-if="viewModel.model.shareThumbnail.length > 0"
              :src="viewModel.model.shareThumbnail[0].src"
              class="img_g">
            <span v-else class="txt_tbl">-</span>
          </td>
        </tr>
        <tr>
          <th>링크명</th>
          <td colspan="3">
            <span class="txt_tbl">{{ viewModel.model.link }}</span>
          </td>
        </tr>
        <tr>
          <th>제목</th>
          <td colspan="3">
            <span class="txt_tbl">{{ viewModel.model.title }}</span>
          </td>
        </tr>
        <tr>
          <th>내용</th>
          <td colspan="3">
            <div class="editor_view" v-html="viewModel.model.contents"></div>
          </td>
        </tr>
      </template>
    </TableView>
    <Sticky>
      <div class="area_left">
        <Button
          btnSize="medium"
          btnStyle="secondary_border"
          text="목록"
          routeName="LANDING_BLOG_POST_LIST"
          :routeQuery="{ pageSize : $route.query.pageSize, page : $route.query.page }"/>
        <Button
          btnSize="medium"
          btnStyle="secondary"
          text="미리보기"
          @onClickBtn="viewModel.onClickPostPreviewPopup()"/>
      </div>
      <div class="area_right">
        <Button
          btnSize="medium"
          btnStyle="fourthly"
          text="삭제"
          @onClickBtn="viewModel.onClickDelete()"/>
        <Button
          btnSize="medium"
          btnStyle="primary"
          text="수정"
          routeName="LANDING_BLOG_POST_MODIFY"
          :routeQuery="{ pageSize : $route.query.pageSize, page : $route.query.page }"/>
        <Button
          btnSize="medium"
          btnStyle="secondary_border"
          text="복제"
          routeName="LANDING_BLOG_POST_REGISTER"
          :routeQuery="{ copyId : viewModel.model.link }"/>
      </div>
    </Sticky>
    <template v-slot:popup>
      <PostPreviewPopup
        v-if="viewModel.isPostPreviewPopup"
        :detailData="viewModel.model"
        @onClickClose="viewModel.onClickClosePostPreviewPopup()"/>
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from '@lemontree-ai/lemontree-admin-common-front/components/layout/PageWithLayout';
import TableView from '@lemontree-ai/lemontree-admin-common-front/components/common/table/TableView';
import Badge from '@lemontree-ai/lemontree-admin-common-front/components/common/badge/Badge';
import Sticky from '@lemontree-ai/lemontree-admin-common-front/components/common/sticky/Sticky';
import Button from '@lemontree-ai/lemontree-admin-common-front/components/common/button/Button';
// popup
import PostPreviewPopup from '@/views/landing/blog/post/view/popup/PostPreviewPopup'
// viewModel
import PostDetailViewModel from '@/views/landing/blog/post/viewModel/PostDetailViewModel'

export default {
  name:'PostDetail',
  components:{
    PageWithLayout,
    TableView,
    Badge,
    Sticky,
    Button,

    PostPreviewPopup
  },
  beforeMount(){
    const link = this.$route.params.link
    this.viewModel.init(link);
  },
  data(){
    return{
      viewModel: new PostDetailViewModel(),
    }
  },
}
</script>
<style scoped>
.img_g{max-width:400px}
</style>