import { GET_REQUEST, POST_REQUEST } from '@/services/http';
import { makeQueryStringByObject } from '@lemontree-ai/lemontree-admin-common-front/utils/urlUtils';
import apiPath from '@/services/apiPath';
import store from '@lemontree-ai/lemontree-admin-common-front/store/index';
import router from '@/router';

export default class NewsListViewModel {
  constructor() {
    this.boardData = {
      title:'공지사항 목록',
      drawDataList: [
        {
          title:'No.',
          width:'60px',
          value:'id',
          isSorting: true
        },
        {
          title:'제목',
          width:'',
          value:'title',
          isEllip: true,
          isAlignLeft: true
        },
        // {
        //   title:'내용',
        //   width:'',
        //   value:'contents',
        //   isEllip: true,
        //   isAlignLeft: true
        // },
        {
          title:'조회수',
          width:'80px',
          value:'viewCount',
          isAlignRight: true,
          emptyValueText:'-'
        },
        {
          title:'상태',
          width:'100px',
          value:'status',
          filter:{
            name:'convertIdToText',
            value:'blog_status'
          },
          badge: {
            badgeColorFilter:{
              name:'convertIdToColor',
              dataListName:'blog_status'
            },
            badgeFill: false
          },
        },
        {
          title:'댓글유무',
          width:'62px',
          value:'useComments',
          filter:{
            name:'convertIdToText',
            value:'blog_comment'
          },
          emptyValueText:'-'
        },
        {
          title: '타겟구분',
          width: '62px',
          value: 'target',
          filter: {
            name: 'convertIdToText',
            value: 'user_target',
          },
          class: {
            classColorFilter: {
              name: 'convertIdToColor',
              dataListName: 'user_target',
              prev: 'tc',
            },
          },
          emptyValueText:'-'
        },
        {
          title:'최종수정일시',
          width:'144px',
          value:'updatedAt',
          filter: { name:'convertDateFormat', value:'YYYY-MM-DD HH:mm' },
          isMobileTitle:true,
          emptyValueText:'-'
        },
        {
          title:'링크복사',
          width:'150px',
          value:'status',
          buttonCondition: [
            { condition:'NORMAL', text:'게시글', size:'small', style:'secondary_border', disabled:false, onClickEvent: (data) => this.onClickCopy(data) },
            { condition:'NORMAL', text:'본문만', size:'small', style:'secondary_border', disabled:false, onClickEvent: (data) => this.onClickCopyChildSafeUrl(data) },
          ],
        },
      ],
      option:{
        isTotal: true,
        isSize: true
      }
    };
    this.dataList = [];
    this.searchParams = {
      page:0,
      pageSize:10,
      direction: 'DESC'
    };
    this.paginationData = {
      totalCount:0,
      totalPage:0
    };
  }

  init(query){
    const { pageSize, page } = query;
    if(pageSize) {
      this.searchParams.pageSize = Number(pageSize);
    }
    if(page) {
      this.searchParams.page = Number(page);
    }
    this.getNewsList();
  }
  getPath(id) {
    const domain =
      process.env.NODE_ENV === 'development'
        ? 'https://www.dev.firfin.family'
        : 'https://www.firfin.family';
    const path = '/blog/news/detail/';

    return `${domain}${path}${id}`;
  }
  onClickCopy(rowData){
    const linkData = this.getPath(rowData.id);
    window.navigator.clipboard.writeText(linkData).then(() => {
      store.dispatch('commonToast/fetchToastStart', `공지사항 링크가 복사되었습니다`);
    });
  }
  onClickCopyChildSafeUrl(rowData){
    const linkData = `${this.getPath(rowData.id)}?isChildSafe=true`;
    window.navigator.clipboard.writeText(linkData).then(() => {
      store.dispatch('commonToast/fetchToastStart', `공지사항 링크가 복사되었습니다`);
    });
  }
  onClickRow(rowId){
    router.push({
      name: 'LANDING_BLOG_NEWS_DETAIL',
      params: { id : rowId},
      query : {
        pageSize : this.searchParams.pageSize,
        page : this.searchParams.page,
      }
    });
  }
  onSearch(){
    this.getNewsList();
  }
  getNewsList(){
    const query = makeQueryStringByObject(this.searchParams);
    const path = `${apiPath.NEWS_LIST}${query}`;
    GET_REQUEST(path).then(
    (success) => {
      const resultData = success.data;
      this.dataList = resultData.data;
      this.paginationData.totalCount = resultData.total;
      this.paginationData.totalPage = resultData.totalPage;
    }, (failed) => {
      store.dispatch('commonToast/fetchToastStart', failed.msg);
    })
  }
}